import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './core/guards/app.guard';
import { AppPageComponent } from './shared/components/app-page/app-page.component';
import { breadcrumbResolver } from './shared/resolvers/breadcrumb.resolver';
import { WelcomeComponent } from './shared/components/welcome-page/welcome.component';
import { customAuthGuard } from './core/guards/auth.guard';
import { GuestGuard } from './core/guards/guest.guard';
import { canActivateSitemapRoute } from './core/guards/sitemap-guard/sitemap.guard';
import { NoClientsComponent } from './shared/components/no-clients/no-clients.component';

const routes: Routes = [
    {
        path: 'login',
        component: WelcomeComponent,
        canActivate: [GuestGuard],
    },
    {
        path: 'no-clients',
        component: NoClientsComponent,
    },
    {
        path: '',
        data: {
            label: 'Dashboard',
            breadcrumb: 'Dashboard',
        },
        canMatch: [customAuthGuard],
        canActivate: [AppGuard],
        component: AppPageComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard',
            },
            {
                path: 'dashboard',
                data: {
                    breadcrumb: null,
                    sitemapConfig: {
                        codes: 'dashboard',
                    },
                },
                resolve: {
                    _: breadcrumbResolver(),
                },
                canActivate: [canActivateSitemapRoute],
                loadComponent: () =>
                    import(
                        './features/dashboard/pages/dashboard.component'
                    ).then((m) => m.DashboardComponent),
            },
            {
                path: 'team',
                data: {
                    label: 'Team',
                    breadcrumb: 'Team',
                },
                resolve: {
                    _: breadcrumbResolver(),
                },
                loadComponent: () =>
                    import('./features/team/pages/team.component').then(
                        (m) => m.TeamComponent
                    ),
            },
            {
                path: 'checklist',
                data: {
                    labelKey: 'checklist',
                    breadcrumb: 'Checklist',
                },
                loadChildren: () =>
                    import('./features/checklist/checklist.module').then(
                        (m) => m.ChecklistModule
                    ),
            },
            {
                path: 'portfolios',
                data: {
                    label: 'Portfolios',
                    breadcrumb: 'Portfolios',
                },
                resolve: {
                    _: breadcrumbResolver(),
                },
                loadChildren: () =>
                    import('./features/portfolios/portfolios.routes').then(
                        (m) => m.PORTFOLIOS_ROUTES
                    ),
            },
            {
                path: 'documents',
                data: {
                    label: 'Documents',
                    breadcrumb: null,
                },
                loadComponent: () =>
                    import(
                        './features/documents/pages/documents.component'
                    ).then((m) => m.DocumentsComponent),
            },
            {
                path: 'messages',
                data: {
                    label: 'Messages',
                    breadcrumb: 'Messages',
                },
                resolve: {
                    _: breadcrumbResolver(),
                },
                loadChildren: () =>
                    import('./features/messages/messages.routes').then(
                        (m) => m.MESSAGES_ROUTES
                    ),
            },
            {
                path: 'insights',
                data: {
                    label: 'Insights',
                    breadcrumb: 'Insights',
                },
                resolve: {
                    _: breadcrumbResolver(),
                },
                loadChildren: () =>
                    import('./features/articles/articles.routes').then(
                        (m) => m.ARTICLES_ROUTES
                    ),
            },
        ],
    },
    {
        path: 'error',
        loadComponent: () =>
            import('./features/error/error.component').then(
                (m) => m.ErrorComponent
            ),
        canMatch: [customAuthGuard],
    },
    { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
