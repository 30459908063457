import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    UrlTree,
} from '@angular/router';
import { Observable, map, forkJoin, take } from 'rxjs';
import { SitemapApiService } from '../../../libs/api-contract/sitemap/sitemap.service';
import { SitemapEntry } from '@wdx/portal/api-models';
import { RouteFacade } from '@wdx/shared/infrastructure/state';
import * as R from 'ramda';
import { NAV_MENU } from '../../../shared/components/app-shell/navigation/navigation.static.data';

export interface SiteMapGuardDataConfig {
    codes: string[];
    redirectToFirstChild?: boolean;
}

/**
 *  Needs to be used with dot separated data.codes value e.g.

    ------

    canActivate: [canActivateSitemapRoute],
    data: { sitemapConfig: { 
           codes: 'portfolios.portfoliostandingorders'
        }
    }

    -----

 */
export const canActivateSitemapRoute: CanActivateFn = (
    route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> | UrlTree => {
    const sitemapService = inject(SitemapApiService);
    const router: Router = inject(Router);
    const routeFacade = inject(RouteFacade);

    if (
        !route.data?.['sitemapConfig'] ||
        !route.data?.['sitemapConfig'].codes
    ) {
        console.error(
            'canActivateSitemapRoute used without setting data.sitemapConfig on route!',
            route.url
        );
        return router.parseUrl('/');
    }

    const { codes } = route.data['sitemapConfig'];

    return forkJoin({
        sitemap: sitemapService.getSitemap().pipe(take(1)),
        path: routeFacade.getPath$().pipe(take(1)),
    }).pipe(
        map(({ sitemap, path }) => {
            let drilldown: SitemapEntry[] | SitemapEntry | undefined = sitemap;
            let siblings: SitemapEntry[] = [];
            const sitemapCodePath = codes.split('.');
            const depth = sitemapCodePath.length;
            sitemapCodePath.forEach((code: string, i: number) => {
                const last = i === sitemapCodePath.length - 1;
                if (last) {
                    siblings = (drilldown as SitemapEntry[]).filter(
                        (dd) => dd.code !== code
                    );
                    drilldown = (drilldown as SitemapEntry[]).find(
                        (dd) => dd.code === code
                    );
                } else {
                    drilldown =
                        (drilldown as SitemapEntry[]).find(
                            (dd) => dd.code === code
                        )?.children || ([] as SitemapEntry[]);
                }
            });
            let redirectUrl = '/';
            if (!drilldown && siblings.length) {
                const firstSiblingCode = R.sort(
                    R.ascend((x) =>
                        R.isNil(x.order) ? Number.MAX_SAFE_INTEGER : x.order
                    ),
                    siblings
                )?.[0]?.code;
                const navConfig = depth === 1 ? NAV_MENU : [];

                const firstSiblingPath =
                    navConfig
                        .find((item) => item.code === firstSiblingCode)
                        ?.routerLink?.[0]?.replace('/', '') || firstSiblingCode;
                redirectUrl =
                    '/' +
                    [
                        ...sitemapCodePath.slice(0, path.length - 1),
                        firstSiblingPath,
                    ].join('/');
            }
            return drilldown || redirectUrl === '/' + path.join('/')
                ? true
                : router.parseUrl(redirectUrl);
        })
    );
};
