import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { FaviconService, ROUTE_PREFIX } from '@wdx/shared/utils';
import { BaseApiService } from '../../../libs/api-contract/base-api.service';
import { Observable, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '../../../libs/api-contract/api-config.service';
import { PortalTenantService } from '../../../core/services/tenants/tenants.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService extends BaseApiService {
    constructor(
        http: HttpClient,
        apiConfig: ApiConfigService,
        private auth0Service: AuthService,
        private faviconService: FaviconService,
        private portalTenantService: PortalTenantService
    ) {
        super(http, apiConfig);
    }

    login(options?: { returnToUrl?: string }): Observable<void> {
        return this.getTenantDetails().pipe(
            tap(({ tenantCode }) =>
                this.faviconService.changeFavicon(tenantCode)
            ),
            tap(
                ({ tenantCode }) =>
                    (this.portalTenantService.tenantCode =
                        tenantCode.toLowerCase())
            ),
            switchMap(({ organisationIdentifier }) =>
                this.loginWithRedirect(organisationIdentifier, options)
            )
        );
    }

    getTenantDetails(): Observable<{
        organisationIdentifier: string;
        tenantCode: string;
    }> {
        return this.get<{ organisationIdentifier: string; tenantCode: string }>(
            `system/tenant/auth0/organisationidentifier?url=${encodeURIComponent(
                window.location.host
            )}`
        );
    }

    loginWithRedirect(
        organisationIdentifier: string,
        options?: { returnToUrl?: string }
    ): Observable<void> {
        return this.auth0Service.loginWithRedirect({
            redirect_uri: window.location.origin,
            organization: organisationIdentifier,
            appState: {
                target: ROUTE_PREFIX[0],
                ...(options?.returnToUrl && {
                    returnToUrl: options.returnToUrl,
                }),
            },
        });
    }
}
